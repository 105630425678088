import React from 'react'
import { graphql } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'
import get from 'lodash/get'

import Layout from '../../components/Layout'

import logoIcon from '../../resources/images/logos/lensesio-logo-icon-b-vsmall.png'

class eula extends React.Component {
  render() {
    const pageSEO = get(this, 'props.data.allContentfulPages.edges[0].node')
    return (
      <Layout>
        <HeaderSEO pageSEO={pageSEO} />
        {/* <!-- ========== End User License Agreement ========== --> */}
        <section className="mt-5 py-5 px-2">
          <div className="container-1">
            <div className="row pt-4">
              {/* Legals / Company Name / Address */}
              <div className="col-md-12 pb-5">
                <table style={{ width: '100%' }} className="f-14 mb-5">
                  <tr>
                    <td align="left" className="text-align-right">
                      <b>Lenses.io Ltd</b> is a company registered in England &
                      Wales
                      <br />
                      Company Registered Number 09975716
                      <br />
                      VAT number: GB 231980705
                      <br />
                      Registered office address
                      <br />
                      C/O Corporation Service Company (Uk) Limited, 5 Churchill
                      Place, 10th Floor,
                      <br />
                      London, E14 5HU, United Kingdom
                    </td>
                  </tr>
                </table>
              </div>

              {/* Purpose of document */}
              <div className="col-md-12">
                <div className="text-center">
                  <img
                    src={logoIcon}
                    alt="Lenses.io logo icon"
                    className="pb-5"
                  />
                  <br />
                  <br />
                  <br />
                  <p className="f-26 fw-600 roboto pb-5">
                    <span className="primary-text">Lenses ®</span>
                    <span> Enterprise</span>
                    <br />
                    <br />
                    End User License Agreement
                  </p>
                </div>
                <br />

                {/* Order */}
                <h1 className="underline h3-like">ORDER</h1>
                <p>
                  <mark>Lenses.io</mark>, a company organised and existing in{' '}
                  <mark>.............................</mark>, with its
                  registered address at{' '}
                  <mark>.............................</mark> (“Lenses.io”); and
                  <br />
                  <br />
                  <mark>.............................</mark>, a company existing
                  in <mark>.............................</mark>
                  with its registered address at{' '}
                  <mark>.............................</mark> (the “Licensee”).
                </p>
                <p>
                  This Order is subject to the provisions of the terms and
                  conditions (“Terms”) attached hereto the End User License
                  Agreement and forms part of the Agreement, as defined in those
                  Terms. Unless expressly stated to the contrary herein, this
                  Order shall be additional to, and shall not supersede, all
                  other Orders and/or Schedules agreed pursuant to the
                  Agreement.
                </p>
                <table
                  className="f-18 table"
                  style={{ width: '60%', borderColor: 'white' }}
                >
                  <tr>
                    <td className="no-border pl-0"> Effective Date </td>
                    <td className="no-border">
                      {' '}
                      <mark>dd/mm/yyyy</mark>{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="no-border pl-0"> Licence Term </td>
                    <td className="no-border">
                      {' '}
                      <mark>12 months</mark>{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="no-border pl-0"> Support Level </td>
                    <td className="no-border">
                      {' '}
                      <mark>Best Effort</mark> or <mark>Professional</mark> or{' '}
                      <mark>Premium </mark>{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className="no-border pl-0"> Charges </td>
                    <td className="no-border">
                      {' '}
                      <mark>xxx $ USD</mark>{' '}
                    </td>
                  </tr>
                </table>
                <p>Scope of use</p>
                <p>
                  <mark>
                    {' '}
                    ......................................................................
                  </mark>
                </p>

                {/* Description ??? */}

                {/* Description ??? */}
                <h3 className="pt-5 pb-3">TERMS AND CONDITIONS</h3>
                <p>
                  These Terms and Conditions form part of the Agreement between
                  Lenses.io and the Licensee whose details appear on the
                  applicable Order and apply to the provision of the Software
                  set out in the Order by Lenses.io to such Licensee.
                </p>
                <p>WHEREAS:</p>
                <ul className="list-style-none">
                  <li>
                    {' '}
                    (A) Lenses.io is the entire legal and beneficial owner of
                    the Software and is willing to license the use of the
                    Software.
                  </li>
                  <li>
                    {' '}
                    (B) The Licensee wishes to obtain a license to use the
                    Software.
                  </li>
                  <li>
                    {' '}
                    (C) Lenses.io wishes to enter into an arrangement to license
                    the use of the Software (defined below) to the Licensee, on
                    the terms and conditions set out in this Agreement.
                  </li>
                </ul>
                <p>
                  NOW, THEREFORE, in consideration of the foregoing, and of the
                  mutual covenants and agreements herein contained, and for
                  other good and valuable consideration, the parties agree as
                  follows:
                </p>
                <p>
                  <b>1. DEFINITIONS</b>
                </p>
                <p>
                  &ldquo;Affiliate&rdquo; means, with respect to the applicable
                  party, any corporation, company, partnership, trust, sole
                  proprietorship or other entity or individual which: (a) is
                  owned or controlled by such party, in whole or in part; (b)
                  owns or controls such party, in whole or in part; or (c) is
                  under common ownership or control with such party, in whole or
                  in part.
                </p>
                <p>
                  &ldquo;Agreement&rdquo; means this license agreement,
                  including all and any Orders and any properly executed
                  variations or addenda.
                </p>
                <p>
                  &ldquo;Charges&rdquo; means those charges and fees for the
                  Software set out in the Order to this Agreement;
                </p>
                <p>
                  &ldquo;Confidential Information&rdquo; means information
                  which:
                </p>
                <ul className="list-style-none">
                  <li> (a) is designated in writing as confidential;</li>
                  <li>
                    {' '}
                    (b) the receiving party knows or reasonably ought to know is
                    confidential due to the nature of the information or the
                    circumstances of disclosure;
                  </li>
                  <li>
                    {' '}
                    (c) is comprised in or relating to a party&apos;s
                    Intellectual Property Rights.
                  </li>
                </ul>
                <p>
                  &ldquo;Effective Date&rdquo; is the date at which the
                  Agreement between the parties is effective.
                </p>
                <p>
                  &ldquo;Intellectual Property Rights&rdquo; means all rights in
                  and to any copyright, trademark, trading name, design, patent,
                  know how (trade secrets) and all other rights resulting from
                  intellectual activity in the industrial, scientific, literary
                  or artistic field and any application or right to apply for
                  registration of any of these rights and any right to protect
                  or enforce any of these rights.
                </p>
                <p>
                  “Order(s)” means the schedule(s) that set out details of the
                  licence granted by Lenses.io to the Licensee, substantially in
                  the form attached hereto.
                </p>
                <p>
                  “Lenses“ is a Software that provides visibility and control
                  over your streaming data. Data discovery via an intuitive web
                  interface, a comprehensive SQL experience for data in motion,
                  monitoring, alerting, data governance, multi-tenancy and
                  security. Α complete user experience to building and managing
                  your data pipelines and micro-services on Apache Kafka and
                  Kubernetes. The product and any available documentation, video
                  or tutorial is distributed electronically from either a
                  download website or email, which will be provided to the
                  Licensee after the acceptance of this Agreement.
                </p>
                <p>
                  “Licence Scope” means the scope of the licence granted by
                  Lenses.io to the Licensee hereunder, as set out in clause 2
                  and the Licence Schedule(s).
                </p>
                <p>
                  “Software” means the software product provided by Lenses.io as
                  set out in the Order in the format set out in the Licence
                  Schedule.
                </p>
                <p>
                  &ldquo;Supported Environment&rdquo; means a software and
                  hardware environment on which the Supported Technologies have
                  been installed; A Supported Environment, also referred to as a
                  &ldquo;Workspace&rdquo;, means the collection of services that
                  altogether form a working environment such as, but not limited
                  to &ldquo;Production&rdquo;, &ldquo;Development&rdquo; or
                  &ldquo;System Integration&rdquo; workspace.
                </p>
                <p>
                  &ldquo;Supported Technologies&rdquo; means the versions of
                  Apache Kafka with which the Software must be used.
                </p>
                <p>
                  &ldquo;Support Level&rdquo; means Best Effort, Professional or
                  Premium as defined publicly at{' '}
                  <a
                    href="https://lenses.io/legals/sla/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    lenses.io/legals/sla
                  </a>
                </p>
                <p>
                  &ldquo;Term&rdquo; means the term of this Agreement, as set
                  out in the Order.
                </p>
                <p>
                  &ldquo;User&rdquo; means, an individual: employee, consultant,
                  contractor or agent of the Licensee, or third party which the
                  Licensee has a business relationship with, who is authorized
                  by the Licensee to use the Software on a Supported Environment
                  and to whom the Licensee has supplied a user identification
                  and password. A user has the right to use the Licensed
                  Software on any computing or communications equipment,
                  regardless of processing power and the right to read, possess
                  and copy the operating manuals.
                </p>
                <p>
                  In the case of conflict or ambiguity between any provision
                  contained in the body of this license and any provision
                  contained in the Order, the provisions in the body shall take
                  precedence.
                </p>
                <p>
                  <b>2 . LICENSE GRANT</b>
                </p>
                <p>
                  <b>2.1</b> In consideration of the fee paid by the Licensee,
                  Lenses.io grants to Licensee a worldwide, non-exclusive,
                  non-transferable, non-sublicensable license for the Term set
                  out in the Order to install and use the Software on the
                  Supported Technologies strictly in accordance with the Licence
                  Scope set out in the Order and subject to the terms and
                  conditions set out in this Agreement and the Licence Schedule.
                </p>
                <p>
                  <b>2.2</b> The Licensee may not use the Software other than as
                  specified in the Agreement and the Order, without the prior
                  written consent of Lenses.io and the Licensee acknowledges
                  that additional fees may be applicable on any change of use
                  approved by Lenses.io. The Licensee may make backup copies of
                  the Software for its lawful use. The Licensee shall record the
                  number and location of all copies and take steps to prevent
                  unauthorised use.
                </p>
                <p>
                  <b>2.3</b> The Licensee shall not, without the prior written
                  consent of Lenses.io;
                </p>
                <ul className="list-style-none">
                  <li>
                    <b>2.3.1</b> Sub-license, assign or novate the benefit or
                    burden of this license in whole or in part; and
                  </li>
                  <li>
                    <b>2.3.2</b> Allow the Software to become the subject of any
                    charge, lien or encumbrance; and
                  </li>
                  <li>
                    <b>2.3.3</b> Deal in any other manner with any or all of its
                    rights and obligations under this Agreement.
                  </li>
                </ul>
                <p>
                  <b>2.4</b> Each party confirms it is acting on its own behalf
                  and not for the benefit of any other person.
                </p>
                <p>
                  <b>2.5</b> The Licensee shall ensure that the number of
                  installations of the Software does not exceed the number
                  specified in the Order and shall notify Lenses.io immediately
                  if it becomes aware of any unauthorised use of the Software by
                  any person.
                </p>
                <p>
                  <b>2.6</b> Nothing in this Agreement will serve to transfer
                  from Lenses.io to the Licensee ownership of the Software, and
                  save for the limited licence expressly set out herein, all
                  rights, titles and interest in and to the same will remain
                  exclusively with Lenses.io.
                </p>
                <p>
                  <b>2.7</b> The Licensee grants to Lenses.io a non-exclusive,
                  worldwide, non-assignable, non-sub-licensable, royalty-free
                  licence to use such information and materials as are provided
                  to Lenses.io by the Licensee for the sole purpose of
                  performing its obligations under this Agreement.
                </p>
                <p>
                  <b>2.8</b> Nothing in this Agreement shall prevent Lenses.io
                  from using any know-how, methods, techniques, or procedures
                  owned or developed by Lenses.io while complying with its
                  obligations hereunder.
                </p>
                <p>
                  <b>2.9</b> The Supported Technologies required by the Licensee
                  to enable it to use the Software shall be procured by the
                  Licensee.
                </p>
                <p>
                  <b>2.10</b> Save as otherwise expressly authorised by this
                  Agreement, the Licensee will not:
                </p>
                <ul className="list-style-none">
                  <li>
                    <b>2.10.1</b> modify, translate or create derivative works
                    of the Software;
                  </li>
                  <li>
                    <b>2.10.2</b> decompile, reverse engineer or reverse
                    assemble any portion of the Software or attempt to discover
                    any source code or underlying ideas or algorithms of the
                    Software;
                  </li>
                  <li>
                    <b>2.10.3</b> sell, assign, sublicense, rent, lease, loan,
                    provide, distribute, share, timeshare, grant a security
                    interest in, use for service bureau purposes, or otherwise
                    transfer all or any portion of the Software, or the
                    Licensee’s right and license to use the Software;
                  </li>
                  <li>
                    <b>2.10.4</b> make, have made, reproduce or copy the
                    Software;
                  </li>
                  <li>
                    <b>2.10.5</b> remove or alter any trademark, logo, copyright
                    or other proprietary notices associated with the Software;
                  </li>
                  <li>
                    <b>2.10.6</b> use the Software in any manner not expressly
                    authorised by this Agreement; or
                  </li>
                  <li>
                    <b>2.10.7</b> cause or permit any other party to do any of
                    the foregoing.
                  </li>
                </ul>
                <p>
                  <b>2.11</b> The Licensee may permit its employees,
                  contractors, or representatives to use the Software in
                  accordance with the Agreement scope, provided that the
                  Licensee takes all necessary steps to procure such employees’
                  compliance with the terms of this Agreement.
                </p>
                <p>
                  <b>2.12</b> The Licensee will put in place all appropriate
                  technological and other security measures to ensure that the
                  Software is physically and electronically secure from
                  unauthorized use or access.
                </p>
                <p>
                  <b>3. THIRD PARTY SOFTWARE</b>
                </p>
                <p>
                  <b>3.1</b> The Software may be delivered with other software
                  or code distributed subject to licenses from third-party
                  suppliers (“Third Party Software”). The Licensee accepts and
                  agrees to the terms of such third-party licenses applicable to
                  the Third Party Software and acknowledges that such
                  third-party suppliers disclaim and make no representation or
                  warranty with respect to the Third Party Software or any
                  portion thereof and assume no liability for any claim that may
                  arise with respect to the Third Party Software. Third Party
                  Software licenses are set forth at:{' '}
                  <a
                    href="https://lenses.io/third-party-software"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    https://lenses.io/third-party-software
                  </a>
                </p>
                <p>
                  <b>4. LICENSEE OBLIGATIONS</b>
                </p>
                <p>
                  <b>4.1</b> The Licensee shall install the Software on the
                  Supported Environment and shall use the Software only in
                  connection with such Supported Environment.
                </p>
                <p>
                  <b>4.2</b> The Licensee shall be responsible for setting its
                  authorised users up with access to the Software, and Lenses.io
                  shall have no liability for any inability of the Licensee’s
                  authorised users to access the Software, or for any
                  unauthorised access to the Software.
                </p>
                <p>
                  <b>4.3</b> The Licensee shall use the Software according to
                  the scope and features described on the ‘Scope of use’ section
                  of this agreement. Lenses.io has the right to audit the
                  Licensee anytime during the term of this Agreement. Lenses.io
                  may audit the Licensees policies, procedures and records that
                  relate to the usage of the Software under this Agreement to
                  ensure compliance with this Agreement upon at least 7 business
                  days’ notice.
                </p>
                <p>
                  <b>5. SUPPORT AND MAINTENANCE</b>
                </p>
                <p>
                  <b>5.1</b> Where expressly set out in the Order, Lenses.io
                  will provide the Licensee with such support and maintenance as
                  is set out in the Support Schedule.
                </p>
                <p>
                  <b>5.2</b> Notwithstanding the provision of support or
                  maintenance by Lenses.io, or any other terms associated with
                  the provision of the same, Lenses.io shall not be obliged to
                  make modifications or provide support in relation to the
                  Licensee&apos;s computer hardware, operating system software,
                  or third party application software or any data feeds or
                  external data.
                </p>
                <p>
                  <b>6. OWNERSHIP AND INTELLECTUAL PROPERTY RIGHTS</b>
                </p>
                <p>
                  <b>6.1</b> As between Lenses.io and the Licensee and subject
                  to the grants under this Agreement, Lenses.io owns all rights,
                  titles and interest in accordance to:
                </p>
                <ul className="list-style-none">
                  <li>
                    <b>6.1.1</b> the Software (including, but not limited to,
                    any modifications thereto or derivative works thereof);
                  </li>
                  <li>
                    <b>6.1.2</b> all ideas, inventions, discoveries,
                    improvements, information, creative works and any other
                    works discovered, prepared or developed by Lenses.io in the
                    course of or resulting from the provision of any services
                    under this Agreement; and
                  </li>
                  <li>
                    <b>6.1.3</b> any and all Intellectual Property Rights
                    embodied in the foregoing.
                  </li>
                </ul>
                <p>
                  <b>6.2</b> The Licensee acknowledges that all Intellectual
                  Property Rights in the Software and any maintenance releases
                  belong and shall belong to Lenses.io, and the Licensee shall
                  have no rights to the Software other than the right to use it,
                  in accordance with the terms of this Agreement.
                </p>
                <p>
                  <b>7. CHARGES</b>
                </p>
                <p>
                  <b>7.1</b> The Software will be provided for the Charges set
                  out in the applicable Order.
                </p>
                <p>
                  <b>7.2</b> All Charges hereunder are exclusive of VAT and any
                  other taxes or duties that shall be charged in addition to the
                  rates in force at the time of application.
                </p>
                <p>
                  <b>7.3</b> Invoices rendered by Lenses.io to a Licensee shall
                  be paid by each Licensee in full, without discount, deduction,
                  set-off or counterclaim of any kind in accordance with the
                  payment terms set out in the relevant Order or otherwise
                  within thirty (30) days of the invoice date.
                </p>
                <p>
                  <b>7.4</b> Should a Licensee fail to make any payment due
                  under the terms of this Agreement by the due date for payment
                  to Lenses.io, then Lenses.io shall be entitled without
                  prejudice to any other right or remedy to charge the Licensee
                  interest on the amount outstanding on a daily basis at the
                  rate of five per cent (5%) per annum above the base rate of
                  the Bank of England from time to time in force, such interest
                  to be calculated from the due date for payment thereof to the
                  date of actual payment.
                </p>
                <p>
                  <b>8. LIMITATION OF LIABILITY</b>
                </p>
                <p>
                  <b>8.1</b> Lenses.io shall not in any circumstances have any
                  liability for any consequential or indirect economic loss or
                  damage.
                </p>
                <p>
                  <b>8.2</b> Furthermore, Lenses.io shall not be liable for any
                  losses or damages which may be suffered by the Licensee (or
                  any other person claiming under or through the Licensee),
                  whether the same are suffered directly or indirectly or are
                  immediate or consequential, and whether the same arise in
                  contract, tort (including negligence) or otherwise, howsoever,
                  which fall within any of the following categories:
                </p>
                <ul className="list-style-none">
                  <li>
                    <b>8.2.1</b> special damage even if Lenses.io was aware of
                    the circumstances in which such special damage could arise;
                  </li>
                  <li>
                    <b>8.2.2</b> loss of profits;
                  </li>
                  <li>
                    <b>8.2.3</b> loss of anticipated savings; loss of business
                    opportunity;
                  </li>
                  <li>
                    <b>8.2.4</b> loss of goodwill;
                  </li>
                  <li>
                    <b>8.2.5</b> loss or corruption of data.
                  </li>
                </ul>
                <p>
                  <b>8.3</b> Subject to clause 8.6, the total liability of
                  Lenses.io, whether in contract, tort (including negligence) or
                  otherwise and whether in connection with this Agreement or any
                  collateral contract, shall not exceed in the aggregate a sum
                  equal to the fee paid or payable as per this Agreement, during
                  the twelve (12) months immediately prior to the event giving
                  rise to this liability; and
                </p>
                <p>
                  <b>8.4</b> The Licensee agrees that, in entering into this
                  Agreement, either it did not rely on any representations
                  (whether written or oral) of any kind or of any person other
                  than those expressly set out in this Agreement or (if it did
                  rely on any representations, whether written or oral, not
                  expressly set out in this Agreement) that it shall have no
                  remedy in respect of such representations and (in either case)
                  Lenses.io shall have no liability in any circumstances
                  otherwise than in accordance with the express terms of this
                  Agreement.
                </p>
                <p>
                  <b>8.5</b> Notwithstanding anything contained in this
                  Agreement, in no event shall Lenses.io be liable for any
                  claims, damages or loss which may arise from the modification,
                  combination, operation or use of the Software with Licensee
                  computer programs.
                </p>
                <p>
                  <b>8.6</b> The exclusions and limitations of liability in
                  clauses 8.1 to 8.5 shall apply to the fullest extent
                  permissible at law, but Lenses.io does not exclude liability
                  for:
                </p>
                <ul className="list-style-none">
                  <li>
                    <b>8.6.1</b> death or personal injury caused by the
                    negligence of Lenses.io, its officers, employees,
                    contractors or agents;
                  </li>
                  <li>
                    <b>8.6.2</b> fraud or fraudulent misrepresentation;
                  </li>
                  <li>
                    <b>8.6.3</b> breach of the obligations implied by section 12
                    of the Sale of Goods Act 1979 or section 2 of the Supply of
                    Goods and Services Act 1982; or
                  </li>
                  <li>
                    <b>8.6.4</b> any other liability which may not be excluded
                    or limited by law.
                  </li>
                </ul>
                <p>
                  <b>8.7</b> All dates supplied by Lenses.io for the delivery of
                  the Software shall be treated as approximate only. Lenses.io
                  shall not in any circumstances be liable for any loss or
                  damage arising from any reasonable delay in delivery beyond
                  such approximate dates.
                </p>
                <p>
                  <b>8.8</b> All references to &ldquo;Lenses.io&rdquo; in this
                  clause 8 shall, for the purposes of this clause only, be
                  treated as including all employees, subcontractors and
                  suppliers of Lenses.io and its Affiliates, all of whom shall
                  have the benefit of the exclusions and limitations of
                  liability set out in this clause.
                </p>
                <p>
                  <b>9. CONFIDENTIALITY</b>
                </p>
                <p>
                  <b>9.1</b> This clause 9 will survive termination of this
                  Agreement.
                </p>
                <p>
                  <b>9.2</b> Each party shall, during the term of this Agreement
                  and thereafter, keep confidential all Confidential
                  Information, and shall not use for its own purposes (other
                  than implementation of this Agreement) nor without the prior
                  written consent of the other disclose to any third party
                  (except its professional advisors or as may be required by any
                  law or any legal or regulatory authority) any Confidential
                  Information, which may become known to such party from the
                  other party, unless that information is public knowledge or
                  already known to such party at the time of disclosure, or
                  subsequently becomes public knowledge other than by breach of
                  this Agreement, or subsequently comes lawfully into the
                  possession of such party from a third party. Each party shall
                  use its reasonable endeavours to prevent the unauthorised
                  disclosure of any such information.
                </p>
                <p>
                  <b>9.3</b> Publicity. Licensee may state publicly that it uses
                  the Software. Any identification or use of a party’s brand,
                  logo or trademark shall conform with the trademark use
                  guidelines provided by one party to the other. Licensee agrees
                  to participate with Lenses.io in publicity events which
                  include the items listed below:
                </p>
                <ul className="list-style-none">
                  <li>
                    <b>9.3.1</b> Use of Licensee’s logo on the Lenses.io website
                    and sales presentations
                  </li>
                  <li>
                    <b>9.3.2</b> Conversations with analysts (subject to
                    separate NDA)
                  </li>
                  <li>
                    <b>9.3.3</b> Providing a quote in a Lenses.io press release
                  </li>
                  <li>
                    <b>9.3.4</b> Full press release announcing Licensee’s
                    engagement with Lenses.io
                  </li>
                  <li>
                    <b>9.3.5</b> Written success story/case study related to
                    Lenses.io
                  </li>
                  <li>
                    <b>9.3.6</b> Video success story/case study related to
                    Lenses.io
                  </li>
                  <li>
                    <b>9.3.7</b> Speaking at Lenses.io events and/or webinar
                  </li>
                  <li>
                    <b>9.3.8</b> Speak to press regarding Lenses.io
                  </li>
                </ul>
                <p>
                  <b>10. DATA PROTECTION</b>
                </p>
                <p>
                  <b>10.1</b> The Licensee and Lenses.io shall comply with all
                  applicable data protection and privacy laws and regulations in
                  the performance of its obligations set out under these Terms,
                  including the EU General Data Protection Regulation 2016/679
                  (“GDPR”) (“Data Protection Laws”), in each case including all
                  other successor legislation and regulation thereto. Full
                  information regarding Lenses.io’s use of personal information
                  can be found in Lenses.io’s Privacy Policy.
                </p>
                <p>
                  <b>10.2</b> Lenses.io may collect, store, transfer and process
                  data regarding the Licensee’s authorised use of the Software
                  (“Telemetry Data”), such as but not limited to IP addresses,
                  information about the browser or device, information regarding
                  the usage: for example when it’s used or how often, which
                  function is used or related statistical information,
                  information about the software for example the version or
                  usage history of the Software. In no case does Lenses.io
                  collect any information from content or data processed by the
                  user. Telemetry Data collection serves exclusively for
                  statistical purposes and for guiding the development of
                  Lenses.io’s products and services. The Licensee may at any
                  time disable the collection of Telemetry Data via the
                  configuration settings of the Software.
                </p>
                <p>
                  <b>11. WARRANTY DISCLAIMER</b>
                </p>
                <p>
                  <b>11.1</b> To the maximum extent permitted by applicable law,
                  the Licensee acknowledges and agrees that the Software is used
                  by Licensee at Licensee’s sole risk and is provided ‘as is’
                  without warranty of any kind, either expressed or implied,
                  including, but not limited to, any (if any) implied warranties
                  of merchantable quality, conditions of fitness for a
                  particular purpose and any warranties arising by statute or
                  otherwise in law or from a course of dealing, course of
                  performance, or use of trade, all of which are hereby excluded
                  and disclaimed.
                </p>
                <p>
                  <b>11.2</b> Lenses.io does not guarantee, warrant or make any
                  representation that the operation of the Software will be
                  uninterrupted or error-free, or that defects in the Software
                  will be corrected. Furthermore, Lenses.io does not guarantee,
                  warrant or make any representations regarding the use or the
                  results of the use of the Software in terms of their
                  correctness, accuracy, reliability or otherwise. No oral or
                  written information or advice given by Lenses.io or an
                  authorised representative of Lenses.io shall create a
                  warranty.
                </p>
                <p>
                  <b>11.3</b> Lenses.io does not warrant that the Software will
                  function in any environment other than on the Supported
                  Technologies and Lenses.io shall not be liable for any failure
                  by the Licensee to properly install the Software on the
                  Supported Environment.
                </p>
                <p>
                  <b>11.4</b> The Licensee acknowledges that:
                </p>
                <ul className="list-style-none">
                  <li>
                    <b>11.4.1</b> The Software has not been prepared to meet any
                    specific requirements of any party, including any
                    requirements of Licensee; and
                  </li>
                  <li>
                    <b>11.4.2</b> it is therefore the responsibility of the
                    Licensee to ensure that the Software meets its own
                    individual requirements.
                  </li>
                </ul>
                <p>
                  <b>11.5</b> All warranties, representations, guarantees,
                  conditions and terms, other than those expressly set out in
                  this Agreement, whether expressed or implied by statute,
                  common law, trade usage or otherwise and whether written or
                  oral, are hereby expressly excluded to the fullest extent
                  permissible under applicable law.
                </p>
                <p>
                  <b>12. INDEMNIFICATION</b>
                </p>
                <p>
                  <b>12.1</b> Lenses.io, at its expense and in its sole
                  discretion, shall defend any claim, demand, action or
                  proceeding against a Licensee asserting that the Software
                  licensed to the Licensee pursuant to an Order infringes any
                  Intellectual Property Rights of any third party (each an
                  “Infringement Claim”) and shall pay any final judgments
                  awarded or settlements entered into with such third party,
                  provided that the Licensee gives prompt written notice to
                  Lenses.io of any such Infringement Claim, grants Lenses.io the
                  full authority to proceed as contemplated herein and uses all
                  reasonable endeavours to mitigate the sums which may be
                  payable by Lenses.io hereunder. The foregoing obligations
                  shall not apply to the extent the alleged infringement arises
                  as a result of or is based upon (i) modifications to the
                  Software not performed by Lenses.io; or (ii) use or
                  combination of the Software with other programs or data.
                </p>
                <p>
                  <b>12.2</b> Lenses.io shall have the exclusive right to defend
                  at its expense any such Infringement Claim, and to make
                  settlements thereof at its own discretion, and the Licensee
                  may not settle or compromise any such Infringement Claim,
                  except with the prior written consent of Lenses.io. The
                  Licensee shall give such assistance and information as
                  Lenses.io may reasonably require in connection with the
                  investigation, defense or settlement such Infringement Claims.
                  In the event any such Infringement Claim is brought or
                  threatened, Lenses.io may at its sole option and expense:
                </p>
                <ul className="list-style-none">
                  <li>
                    <b>12.2.1</b> procure for Licensee the right to continue use
                    of the Software or the allegedly infringing part thereof; or
                  </li>
                  <li>
                    <b>12.2.2</b> modify or amend the Software or the allegedly
                    infringing part thereof, or replace the Software so the
                    Software or part thereof as so modified, amended or replaced
                    has substantially the same or better capabilities as the
                    original Software or part thereof; or
                  </li>
                  <li>
                    <b>12.2.3</b> if neither of the foregoing solutions is
                    commercially practicable, Lenses.io may terminate this
                    Agreement with respect to the allegedly infringing part of
                    the Software and for term licenses Lenses.io shall refund
                    the licence fees paid by Licensee which are attributable to
                    any future period for which license fees have been paid in
                    advance but which the Software will not be used. Lenses.io
                    will then be released from any further obligation whatsoever
                    to Licensee in connection with the alleged infringing part
                    of the Software.{' '}
                  </li>
                </ul>
                <p>
                  <b>13. GOVERNING LAW</b>
                </p>
                <p>
                  <b>13.1</b> This Agreement and any dispute or claim arising
                  out of or in connection with it or its subject matter or
                  formation (including non-contractual disputes or claims) shall
                  be governed by and construed in accordance with the laws of
                  England and Wales
                </p>
                <p>
                  <b>13.2</b> The parties irrevocably agree that the courts of
                  England and Wales shall have exclusive jurisdiction to settle
                  any dispute or claim that arises out of or in connection with
                  this Agreement or its subject matter or formation (including
                  non-contractual disputes or claims).
                </p>
                <p>
                  <b>14. TERM AND TERMINATION</b>
                </p>
                <p>
                  <b>14.1</b> This Agreement commences on the Effective Date and
                  shall continue until terminated by either party on the
                  provision of not less than sixty (60) days written notice to
                  the other, save that no such termination shall be effective
                  until all Licence Schedules have expired or have been
                  terminated.
                </p>
                <p>
                  <b>14.2</b> Lenses.io may terminate this Agreement or any
                  Order in the event of any of the following:
                </p>
                <ul className="list-style-none">
                  <li>
                    <b>14.2.1</b> if the Licensee is in breach of any term of
                    this Agreement and has not corrected such breach to
                    Lenses.io’s reasonable satisfaction within 15 days of
                    Lenses.io’s notice of the same; or
                  </li>
                  <li>
                    <b>14.2.2</b> if the Licensee becomes insolvent, or
                    institutes (or there is instituted against it) proceedings
                    in bankruptcy, insolvency, reorganization or dissolution, or
                    makes an assignment for the benefit of creditors.
                  </li>
                </ul>
                <p>
                  <b>14.3</b> Termination under this clause shall not affect any
                  other rights or remedies Lenses.io may have.
                </p>
                <p>
                  <b>14.4</b> On termination of this Agreement for any reason:
                </p>
                <ul className="list-style-none">
                  <li>
                    <b>14.4.1</b> Lenses.io shall be entitled to be paid all
                    sums due and any additional sums specified in each Licence
                    Schedule; and
                  </li>
                  <li>
                    <b>14.4.2</b> all licences granted pursuant this Agreement
                    and the Order(s) shall immediately terminate.
                  </li>
                </ul>
                <p>
                  <b>14.5</b> On termination of this Agreement for any reason
                  this Agreement shall continue in force to the extent necessary
                  to give effect to those of its provisions which expressly or
                  impliedly have effect after termination.
                </p>
                <p>
                  <b>15. ASSIGNMENT</b>
                </p>
                <p>
                  <b>15.1</b> Licensee shall not assign any rights of this
                  Agreement, without the prior written consent of Lenses.io.
                </p>
                <p>
                  <b>16. NO PARTNERSHIP OR AGENCY</b>
                </p>
                <p>
                  <b>16.1</b> Nothing in this Agreement is intended to, or shall
                  be deemed to establish any partnership or joint venture
                  between any of the parties, or constitute any party the agent
                  of another party, or authorise any party to make or enter into
                  any commitments for or on behalf of any other party.
                </p>
                <p>
                  <b>17. COUNTERPARTS</b>
                </p>
                <p>
                  <b>17.1</b> This Agreement may be executed in any number of
                  counterparts, each of which when executed and delivered, shall
                  constitute a duplicate original but all the counterparts shall
                  together constitute one Agreement.
                </p>
                <p>
                  <b>18. SEVERANCE</b>
                </p>
                <p>
                  <b>18.1</b> If any provision or part-provision of this
                  Agreement is or becomes invalid, illegal or unenforceable, it
                  shall be deemed modified to the minimum extent necessary to
                  make it valid, legal and enforceable. If such modification is
                  not possible, the relevant provision or part-provision shall
                  be deemed deleted. Any modification to or deletion of a
                  provision or part-provision under this clause shall not affect
                  the validity and enforceability of the rest of this agreement.
                </p>
                <p>
                  <b>18.2</b> If any provision or part-provision of this
                  Agreement is invalid, illegal or unenforceable, the parties
                  shall negotiate in good faith to amend such provision so that,
                  as amended, it is legal, valid and enforceable, and, to the
                  greatest extent possible, achieves the intended commercial
                  result of the original provision.
                </p>
                <p>
                  <b>19. WAIVER</b>
                </p>
                <p>
                  <b>19.1</b> No failure or delay by a party to exercise any
                  right or remedy provided under this Agreement or by law shall
                  constitute a waiver of that or any other right or remedy, nor
                  shall it prevent or restrict the further exercise of that or
                  any other right or remedy. No single or partial exercise of
                  such right or remedy shall prevent or restrict the further
                  exercise of that or any other right or remedy.
                </p>
                <p>
                  <b>20. FORCE MAJEURE</b>
                </p>
                <p>
                  <b>20.1</b> Neither party shall be in breach of this Agreement
                  nor liable for delay in performing, or failure to perform, any
                  of its obligations under this agreement if such delay or
                  failure result from events, circumstances or causes beyond its
                  reasonable control. In such circumstances the affected party
                  shall be entitled to a reasonable extension of the time for
                  performing such obligations. If the period of delay or
                  non-performance continues for 6 weeks, the party not affected
                  may terminate this Agreement by giving 10 days&apos; written
                  notice to the affected party.
                </p>
                <p>
                  <b>21. NOTICES</b>
                </p>
                <p>
                  <b>21.1</b> Any notice given to a party under or in connection
                  with this contract shall be in writing and shall be:
                </p>
                <ul className="list-style-none">
                  <li>
                    <b>21.1.1</b> delivered by hand or by pre-paid first-class
                    post or other next working day delivery service at its
                    registered office or its principal place of business (in any
                    other case); and
                  </li>
                  <li>
                    <b>21.1.2</b> sent by email to info@lenses.io
                  </li>
                  <li>
                    <b>21.2.3</b> if sent by email, at 9.00 am on the next
                    Business Day after transmission.
                  </li>
                </ul>
                <p>
                  <b>22. ENTIRE AGREEMENT</b>
                </p>
                <p>
                  <b>22.1</b> This Agreement (including these Terms, the Order
                  and any other Schedules referred to in the Order) contains the
                  entire agreement between the Parties and supersedes any
                  previous understanding, commitments or agreements, oral or
                  written. Further, this Agreement may not be modified, changed,
                  or otherwise altered in any respect except by a written
                  agreement signed by both Parties.
                </p>

                <hr className="pt-2" />
                <p className="pt-2">
                  Last update: 11 Oct 2021
                  <br />
                  <br />
                  <small>
                    {' '}
                    These terms and conditions may be updated from time to time,
                    with the understanding that any such updates will not
                    materially reduce the terms and conditions experienced by
                    the Customer.
                  </small>
                </p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
export default eula

export const pageQuery = graphql`
  query legalsPolicySeoAndLegalsPolicySeo {
    allContentfulPages(
      filter: { pageReference: { pageIdentifier: { eq: "Legals_Policy" } } }
    ) {
      edges {
        node {
          title {
            title
          }
          keywords {
            keywords
          }
          description {
            description
          }
          oGimage {
            fluid(quality: 100) {
              src
            }
          }
        }
      }
    }
  }
`
